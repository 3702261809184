<template>
    <div class="loans-list">
        <div class="loans-list__list">
            <div
                v-for="(partner) in offers"
                :key="partner.id"
            >
                <Partner
                    class="loans-list__item"
                    :data="partner"
                />
            </div>
        </div>
        <div
            v-if="this.response.targets && this.response.targets.other.length && !showMore"
            class="loans-list__actions"
        >
            <Button
                @click.native="showMore = true"
                withoutIcon
            >
                Показать еще
            </Button>
        </div>
    </div>
</template>

<script>
import './loans-list.scss'

import final from '@/api/final'

export default {
    name: 'List',
    data() {
        return {
            showMore: false,
            response: {}
        }
    },
    created() {
        this.getOffers();
    },
    computed: {
        offers() {
            if (!this.showMore)
                return this.response.targets?.default

            return [
                ...this.response.targets?.default,
                ...this.response.targets?.other,
            ]
        },
    },
    methods: {
        async getOffers() {
            const response = await final();
            // @TODO предусмотреть ошибку? тест
            this.response = response;
        }
    },
    components: {
        Partner: () => import('@/applications/final/components/partner/Partner')
    }
}
</script>