<template>
    <CommonTemplate
        class="final-page"
    >
        <template v-slot:title>
            Мы подобрали Вам займы.
        </template>
        <template v-slot:desc>
            Одновременно подайте заявку, как минимум, в 3 компании для гарантированного получения займа.
        </template>
        <List/>
    </CommonTemplate>
</template>

<script>
import './final-page.scss'

import List from '@/applications/final/components/list/List'

export default {
    name: 'Final',
    created() {
        if (document.querySelector('script[src="//web.webpushs.com/js/push/f62289e24274b2bc2a4952fbc73994d5_1.js"]'))
            return

        const script = document.createElement('script')
        script.src = '//web.webpushs.com/js/push/f62289e24274b2bc2a4952fbc73994d5_1.js'
        script.async = true
        document.head.appendChild(script)
    },
    components: {
        List
    },
}
</script>